
import Vue from "vue";

export default Vue.extend({
  name: "Community",
  props: {
    profile: Object,
    data: Object
  },
  data() {
    return {
      communityInfo: [
        {
          label: "Followers",
          icon: "users",
          name: "followers"
        },
        {
          label: "Mentored via session",
          icon: "session-delivered",
          name: "mentored_sessions"
        },
        {
          label: "Mentored via Mentorcast",
          icon: "scene",
          name: "mentored_mentorcasts"
        },
        {
          label: "Comments",
          icon: "chat",
          name: "comments"
        },
        {
          label: "Rated by",
          icon: "rating",
          name: "rated_by"
        }
      ]
    };
  }
});
