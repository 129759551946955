
import Vue from "vue";

export default Vue.extend({
  name: "CommunityBadges",
  props: {
    badges: {
      type: Array
    }
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    }
  }
});
