
import Vue from "vue";

export default Vue.extend({
  name: "CommunityItem",
  props: {
    title: String,
    users: Array,
    type: String
  },
  data() {
    return {};
  },
  methods: {
    strLimit(text: string, count: number) {
      return text.slice(0, count) + (text.length > count ? "..." : "");
    },
    profileLink(data: any, type: any) {
      let user = data;
      if (type === "comment") {
        user = data.user;
      }
      if (user.type == "mentee") {
        return `/mentorees/${user.uuid}`;
      }
      if (user.type == "mentor") {
        return `/mentors/${user.uuid}`;
      }
    }
  }
});
