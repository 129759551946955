
import Vue from "vue";
import { mapGetters } from "vuex";
import Community from "@/components/Community/Community.vue";
import CommunityItem from "@/components/Community/CommunityItem.vue";
import CommunityBadges from "@/components/Community/CommunityBadges.vue";
import CommunityLoading from "@/components/Community/CommunityLoading.vue";
import CommunityItemsLoading from "@/components/Community/CommunityItemsLoading.vue";

export default Vue.extend({
  name: "CommunityPage",
  components: {
    Community,
    CommunityItem,
    CommunityBadges,
    CommunityLoading,
    CommunityItemsLoading
  },
  data() {
    return {
      loading: true,
      tabs: [
        {
          name: "All time",
          type: "all"
        },
        {
          name: "This Week",
          type: "week"
        },
        {
          name: "This Month",
          type: "month"
        }
      ],
      currentTab: "all"
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      community: "profile/getCommunity"
    })
  },
  mounted(): void {
    this.getCommunity();
  },
  methods: {
    setTab(tab: string) {
      this.currentTab = tab;
      this.getCommunity();
    },
    getCommunity() {
      this.loading = true;
      this.$store
        .dispatch("profile/GET_COMMUNITY", {
          id: this.$route.params.id,
          filter: this.currentTab
        })
        .then(() => {
          this.loading = false;
        });
    }
  }
});
